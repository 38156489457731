var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"geko-base-detail-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-data-table',{staticClass:"rounded-xl elevation- pa-1 px-5",attrs:{"headers":_vm.configMonitoringDetail.table.header,"items":_vm.data.list_detail,"search":''},scopedSlots:_vm._u([{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"top",fn:function(){return [_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Detail Pohon Monitoring Lahan Umum")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])])]},proxy:true}])})],1)],1),_c('v-col',[_c('v-card',{staticClass:"geko-base-detail-card mb-5 px-4",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Dokumentasi Penilikan Tanam Lahan Umum")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])]),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
                    backgroundImage:
                    'url(' +
                    _vm.$_config.baseUrlUpload +
                    '/' +
                    _vm.data.gambar1 ?? '' +
                    ')',
                }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.gambar1 ?? '')}}},[_c('h6',[_vm._v("Foto Realisasi Tanam")])])]):_vm._e(),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
                    backgroundImage:
                    'url(' +
                    _vm.$_config.baseUrlUpload +
                    '/' +
                    _vm.data.gambar2 ?? '' +
                    ')',
                }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.gambar2 ?? '')}}},[_c('h6',[_vm._v("Foto Realisasi Tanam")])])]):_vm._e(),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
                    backgroundImage:
                    'url(' +
                    _vm.$_config.baseUrlUpload +
                    '/' +
                    _vm.data.gambar3 ?? '' +
                    ')',
                }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.gambar3 ?? '')}}},[_c('h6',[_vm._v("Foto Tanaman Mati")])])]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }